
.newLink {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #2985fd;
    min-height: 50px;
    color: white;
}

.newLink>div:nth-child(1) {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
}

.newLink>div:nth-child(1)>svg {
    margin-left: 20px;
    width: 30px;
}

.newLink>div>h1 {
    font-size: 12px;
    color: #fff;
    margin-left: 30px;
}

.newLink>div:nth-child(2) {
    border: 1px solid rgba(255, 255, 255, .15);
    border-radius: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 5px;
    margin-right: 20px;
}