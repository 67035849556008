.Register-main{
    width: 100%;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Register-main>div{
    width: 50%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 10px 15px 5px rgb(0 0 0 / 12%);
}
.Register-main>div>h1{
    color: rgba(1, 103, 255, 0.87);
    text-transform: capitalize;
    line-height: 1.2;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 2rem;
    padding: 5px;
    border-bottom: 2px solid rgba(1, 103, 255, 0.87);
}
.register{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.register>input:nth-of-type(1) ,
.register>input:nth-of-type(2) ,
.register>input:nth-of-type(3) ,
.register>input:nth-of-type(4) , 
.register>select{
    width: 400px;
    box-shadow: inset 0 2px 3px rgb(10 10 10 / 20%);
    padding: 10px 20px;
    border: none;
    outline: none;
    margin-bottom: 2px;
    border-radius: 3px;
    margin-bottom: 20px;
}
.checkbox{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.checkbox>div{
    display: flex;
    align-items: center;
    text-align: left;
    margin-left: 2px;
}
.checkbox>div>input{
    margin-right: 10px;
}
.checkbox>div>h5{
    font-weight: 200;
    font-size: 1rem;
}
.checkbox>div:last-child{
    margin-top: 10px;
}
.checkbox>div>h6{
    font-weight: 600;
    font-size: 0.8rem;
}
.checkbox>div>h6>span{
    color: rgba(1, 103, 255, 0.87);
}
.register>textarea{
    width: 400px;
    box-shadow: inset 0 2px 3px rgb(10 10 10 / 20%);
    padding: 10px 20px;
    border: none;
    outline: none;
    margin-bottom: 2px;
    border-radius: 3px;
    margin-bottom: 20px;
    resize: vertical;
}
.register>h4{
    margin-bottom: 12px;
    line-height: 1.2;
    font-size: 15px;
    font-weight: 600;
    width: 90%;
}
.register>h4>span{
    color: red;
    margin-left: 2px;
}

.register>select{
    color: gray;
}
.register>select>option{
    color: black;
}
/* .register>input:hover{
    box-shadow: inset 0 1px 2px #0c5adb

} */
.register>button{
    width: 300px;
    box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
    padding: 10px 20px;
    border: none;
    outline: none;
    color: rgba(1, 103, 255, 0.87);
    line-height: 1.2;
    font-weight: 300;
    background-color: transparent;
    border: 1px solid rgba(1, 103, 255, 0.87);
    font-size: 1.2rem;
    margin-top: 20px;
    transition: .2s linear;
}
.register>button:hover{
    background-color:rgba(1, 103, 255, 0.87);
    color: white;
    cursor: pointer;
}
.register>input:hover ,
.register>select:hover ,
.register>textarea:hover{
    box-shadow: inset 0 1px 3px #0c5adb

}
