.pannel-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

.pannel-r {
    flex: 80%;
    width: 80%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    background-color: #f3f6fb;
    min-height: 1200px;
}


.report {
    width: 100%;
    padding: 20px 30px;
}

.TodayReport {
    margin-left: 20px;
    margin-right: 20px;
    background: #398BF7;
    padding: 12px;
    margin-top: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.TodayReport>h1 {
    font-size: 14px;
    line-height: 1.42857143;
    font-weight: 500;
    color: #fff;
}

.TodayReport>h1>span {
    margin: 0px 5px;
}

.report-boxs {
    display: flex;
    margin-bottom: 30px;
}

.report-box {
    max-width: 24%;
    flex: 0 0 24%;
    padding: 1.5rem 1.5rem;
    background-color: white;
    border-radius: 5px;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
}

.report-box-l {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    width: 100%;
}

.report-box-l>div {
    display: flex;
    justify-content: space-between;
}

.report-box-l>div>div>h3 {
    line-height: 1.5;
    color: #8898aa;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}

.report-box-l>div>div>h4 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #32325d;
    line-height: 1.5;
    margin-bottom: 10px;
}

.report-box-l>h5 {
    display: flex;
    align-items: center;
    text-align: center;
    color: #32325d;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    font-family: Montserrat, sans-serif;
}

.report-box-l>h5>span {
    display: flex;
    align-items: center;
    text-align: center;
    color: #00c292;
    margin-right: 0.5rem;
    margin-left: -5px;
}

.report-box-r {}

.report-img {
    border-radius: 50%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
    color: white;
}

.announcement {
    width: 100%;
    padding: 20px 30px;
    margin-top: -20px;
}

.anno-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
}

.blue-box {
    padding: 10px;
    margin-bottom: 10px;
}

.blue-box>h2 {
    font-size: 15px;
    margin: 0;
    line-height: 1;
    font-weight: 500;
    color: #2985fd;
    border: 1px solid #2985fd;
    padding: 6px;
    border-radius: 3px;
    display: inline-block;
    font-family: inherit;
}

.anno-main {
    padding: 5px 20px 5px 10px;
}

.anno-main>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.anno-main>div>h1 {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 13px;
    color: #141823;
    font-weight: 700;
    line-height: 1.4;
    font-family: Montserrat, sans-serif;
}

.anno-main>div>h3 {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    color: #141823
}

.anno-main>div>h3>svg {
    width: 15px;
    font-weight: 200;
    opacity: 0.7;
    margin-right: 5px;
}

.anno-main>div>h1>svg {
    margin-left: -5px;
    opacity: 0.7;


}

.anno-main>h4 {
    display: inline-block;
    background-color: #e74c3c;
    font-size: 13px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    line-height: 1.4;
}

.anno-main>p {
    font-size: 13px;
    font-weight: 100;
    color: #141823;
    font-family: Montserrat, sans-serif;
    line-height: 1.4;
    margin-top: -5px;
    margin-bottom: 20px;
}

.anno-main>ul {
    list-style-type: none;
    margin-bottom: 20px;
}

.anno-main>ul>li {
    font-size: 13px;
    font-weight: 500;
    color: #141823;
    font-family: Montserrat, sans-serif;
    line-height: 1.4;
}

.anno-main>p>span {
    font-weight: 900;
    color: black;
    margin-right: 5px;
}

.graph {
    width: 100%;
    padding: 20px 30px;
    margin-top: -20px;
}

.graph-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
}

.graph-blue-box {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.graph-blue-box>h2 {
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    color: #2985fd;
    border: 1px solid #2985fd;
    padding: 6px;
    border-radius: 3px;
    display: inline-block;
    font-family: inherit;
}

.graph-blue-box>select {
    font-family: Montserrat, sans-serif;
    height: 28px;
    line-height: 1;
    font-size: 13px;
    padding: 3px;
    color: #2985fd;
    font-weight: 500;
    border-radius: 3px;
    outline: none;
    box-shadow: none;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;
}

.earnhistory {
    width: 100%;
    padding: 20px 30px;
}

.earnhistory-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
}

.table {
    height: 300px;
    overflow: auto;
}

table {
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 13px;
    word-wrap: normal;
    text-align: center;
}

table>thead>tr>th {
    border-bottom: 2px solid #fff;
    text-align: center;
}

table>tbody>tr>td,
table>tbody>tr>th,
table>thead>tr>td,
table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
}

table>tbody>tr:nth-of-type(odd) {
    border-color: #f3f6fb;
    background: #f3f6fb;
}


.topLink{
    width: 100%;
    padding: 20px 30px;
}
.topLink-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
}
.toplink-table{
    margin-bottom: 0;
}
