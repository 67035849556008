.withdraw-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

.withdraw-r{
    flex: 80%;
    width: 80%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    background-color: #f3f6fb;
    min-height: 1200px;
}
.withdraw{
    width: 100%;
    padding: 20px 30px;
}
.payment{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.payment-box{
    max-width: 31.2%;
    flex: 45%;
    background-color: white;
    border-radius: 0.25rem;
    padding: 25px;
    padding: 2.5rem;
    margin: 10px 10px;
    height: 100%;

}
.p-boxL{
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-boxL>svg{
    color: green;
    margin-right: 20px;
}
.p-boxL>div>h3{
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
}
.p-boxL>div>h5{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.1;
}
.withdraw-main{
    background-color: white;
    border-radius: 0.25rem;
    padding: 25px;
    margin: 0px 10px;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
    margin-bottom: 200px;
}
.withdraw-btn{
    margin-bottom: 10px;
}
.withdraw-btn>button{
    color: #fff;
    background-color: #00c292;
    border-color: #00c292;
    width: 100%;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 1.2;
    border-radius: 4px;
    display: block;
    box-shadow: none;
    border: 1px solid transparent;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    font-family: Montserrat,sans-serif;
    transition: .3s linear;
}
.withdraw-btn>button:hover{
    background-color: #0e8a6b;
}
.withdraw-dec{
    border-top: 1px solid #eee;;
    border-bottom: 1px solid #eee;
    padding: 20px 0px;
}
.withdraw-dec>h6{
    font-size: 13px;
    color: #696b70;
    margin: 0 0 10px;
    font-weight: 500;
    font-family: Montserrat,sans-serif;
    line-height: 1.4;
}
.withdraw-tableM{
    height: 400px;
    border-bottom: 1px solid #eee;
    overflow: auto;
    margin-bottom: 20px;
}
.withdraw-table{
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: scroll;
    font-size: 13px;
}
.withdraw-table>thead>tr>th>span{
    color: #2985fd;
}
.withdraw-rule{
    padding: 0px 40px;
    margin-bottom: 30px;
}
.withdraw-rule>ul{
    margin-bottom: 10px;
}
.withdraw-rule>ul>li{
    font-family: Montserrat,sans-serif;
    font-size: 14px;
    color: #3e3f41;;
    font-weight: 500;
    line-height: 1.4;
}