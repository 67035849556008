.navbar-wrapper {
    background-color: transparent;
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0;
    padding: 10px 5%;
    width: 100%;
    height: 120px;
    position: relative;
}

.navbar-l {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.navbar-logo{
    padding: 0;
    min-height: 106px;
    display: flex;
    align-items: center;
}
.navbar-logo>a>img{
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
.navbar-b {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 800px;
}
.navbar-b>ul{
    list-style-type: none;
    color: white;
    display: flex;
    justify-content: center;
}
.navbar-b>ul>li{
    padding: 44px 18px;
    font-weight: 700;
    font-family: nunito;
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
}
.navbar-b>ul>a>li{
    padding: 44px 18px;
    font-weight: 700;
    font-family: nunito;
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    color: white;    
    transition: .3s linear;

}
.navbar-b>ul>a{
    text-decoration: none;
}
.navbar-b>ul>a>li:hover{
    color: rgba(0, 19, 142, 0.75);

}

.navbar-r {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
}
.navbar-Country{
    background: url(../../img/Navbar/flag.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
    margin-right: 9px;
}
.navbar-r>h3{
    color: white;
    font-size: .9333333333rem;
    font-weight: 700;
    margin-right: 20px;

}
.navbar-r>span{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.navbar-toggle{
    position: absolute;
    margin-left: 80px;
    margin-top: 130px;
    border: 5px solid white;
    border-radius: 50%;
    background-color: #00138e;
    padding: 10px;
}
.navbar-toggle>span{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}