.login-wrapper{
    background: linear-gradient(rgba(1, 103, 255, 0.87) ,rgba(1, 103, 255, 0.87)) ,url(../../img/Register/register.png);
    background-color: #0c5adb;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
}
.login{
    color: white;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding: 100px 20px;
}
.login>h1{
    font-size: 2.4rem;
    color: #fefefe;
    font-weight: 700;
}

.Already{
    background: linear-gradient(rgba(1, 103, 255, 0.87) ,rgba(1, 103, 255, 0.87)) ,url(../../img/Register/register.png);
    background-color: #0c5adb;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
}
.Already>div{
    display: flex;
    color: white;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding: 100px 20px;
    justify-content: space-between;
    align-items: center;
}
.Already>div>h1{
    font-size: 2.4rem;
    color: #fefefe;
    font-weight: 700;
    width: 30%;
    line-height: 1.2;

}
.Already>div>button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #10707f;
    font-size: 15px;
    font-weight: 300;
    box-shadow: 0 10px 10px 0 rgb(0 0 0 / 12%);
    height: 55px;
    line-height: 55px;
    border-radius: 3px;
    padding: 0 51px;
    border: none;
    transition: .3s linear;
}
.Already>div>button:hover{
    cursor: pointer;
    background-color: #001d4c;
    color: white;
}
.login-main{
    width: 100%;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-main>div{
    width: 50%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 10px 15px 5px rgb(0 0 0 / 12%);
}
.login-main>div>h1{
    color: rgba(1, 103, 255, 0.87);
    text-transform: capitalize;
    line-height: 1.2;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 2rem;
    padding: 5px;
    border-bottom: 2px solid rgba(1, 103, 255, 0.87);
}
.login-main>div>input{
    width: 300px;
    box-shadow: inset 0 1px 2px rgb(10 10 10 / 20%);
    padding: 10px 20px;
    border: none;
    outline: none;
    margin-bottom: 2px;
    border-radius: 3px;
}
.login-main>div>input:hover{
    box-shadow: inset 0 1px 2px #0c5adb

}
.login-main>div>div>button{
    width: 300px;
    box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
    padding: 10px 20px;
    border: none;
    outline: none;
    color: rgba(1, 103, 255, 0.87);
    line-height: 1.2;
    font-weight: 300;
    background-color: transparent;
    border: 1px solid rgba(1, 103, 255, 0.87);
    font-size: 1.2rem;
    margin-top: 20px;
    transition: .2s linear;
}
