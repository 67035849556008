.support-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.support-r {
    flex: 80%;
    width: 80%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    background-color: #f3f6fb;
    min-height: 800px;
}
.support{
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 200px;
}
.support-box{
    background-color: white;
    padding: 25px;
    border-radius: 0.5rem;
    width: 100%;
}
.support-box>div>h1{
    color: #91a6c1;
    font-weight: 500;
    font-size: 13px;
    margin-top: 15px;
    max-width: 100%;
    margin-bottom: 5px;
    font-family: Montserrat,sans-serif;
    line-height: 1.4;
    margin-left: 3px;
}
.support-box>div>input:nth-child(2)  {
    display: block;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #495057;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;
    outline: #2985fd;
}
.support-box>div>textarea{
    display: block;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #495057;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;
    outline: #2985fd;
    font-family: Montserrat,sans-serif;
    resize: vertical;
}
.support-box>div:nth-child(5){
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 2px;
}
.support-box>div:nth-child(5)>input{
    margin-right: 10px;
}
.support-box>div:nth-child(5)>h2{
    font-weight: 400;
    cursor: pointer;
    font-size: 13px;
    color: #3e485b;
}
.support-box>div:nth-child(5)>h2>span{
    color: #2985fd;

}
.support-box>button{
    color: #fff;
    border-color: #2985fd;
    background-color: #2985fd;
    border-radius: 3px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 1.42857143;
    font-family: inherit;
}