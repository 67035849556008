.referral-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}
.referral-r {
    flex: 80%;
    width: 80%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    background-color: #f3f6fb;
    min-height: 1000px;
}
.referral{
    width: 100%;
    padding: 20px 30px;
}
.referral-link{
    background-color: white;
    border-radius: 0.25rem;
    padding: 25px;
    border: 1px solid #d2d6de;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
    margin-bottom: 25px;
}
.referral-link>h6{
    color: #141823;
    font-size: 13px;
    margin: 0 0 20px;
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    line-height: 1.42857143;
}
.referral-link>input{
    width: 100%;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}
.referral-all{
    background-color: white;
    border-radius: 0.25rem;
    padding: 25px;
    border: 1px solid #d2d6de;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
    margin-bottom: 300px;
}
.referral-blue-box {
    padding: 10px;
    margin-bottom: 10px;
}

.referral-blue-box>h2 {
    font-size: 15px;
    margin: 0;
    line-height: 1;
    font-weight: 500;
    color: #2985fd;
    border: 1px solid #2985fd;
    padding: 5px;
    border-radius: 3px;
    font-family: inherit;
    display: flex;
    align-items: center;
    max-width: 130px;
}

.referral-blue-box>h2>svg{
    width: 20px;
    margin-right: 5px;
}
.table-ref{
    overflow: auto;
    height: 400px;
}
.referral-table{
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: scroll;
}

.referral-table>thead>tr {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    border-top: 1px solid #f3f3f3;
    text-align: center;
    background: #f3f6fb;
    font-size: 13px;
}