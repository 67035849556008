.pannel-l {
    background-color: white;
    width: 20%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex: 20%;
}

.pannel-l>h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
    font-weight: 200;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 50px;
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.profile>div {
    width: 90px;
    height: 90px;
    border-radius: 100%;
}

.profile>div>img {
    max-width: 100%;
    border-radius: 100%;
}

.profile>h3 {
    color: #2985fd;
    text-align: center;
    line-height: 1;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
}

.pannel-navbar {
    width: 100%;

}

.pannel-navbar>ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
}

.pannel-navbar>ul>li ,
.pannel-navbar>ul>a>li{
    display: flex;
    align-items: center;
    text-align: center;
    margin: 2px;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1.42857143;
    font-weight: 500;
    position: relative;
    cursor: pointer;
}
.pannel-navbar>ul>li:hover ,
.pannel-navbar>ul>a>li:hover{
    background-color: #e8eff4;

}
.pannel-navbar>ul>a{
    text-decoration: none;
    color: black;
    
}
.pannel-navbar>ul>li>svg:nth-of-type(1) ,
.pannel-navbar>ul>a>li>svg:nth-of-type(1) {
    margin-right: 20px;
    width: 20px;
    opacity: 0.7;
}

.pannel-navbar>ul>li>svg:nth-of-type(2) ,
.pannel-navbar>ul>a>    li>svg:nth-of-type(2) {
    position: absolute;
    right: 5%;
}

