.footer-wrapper{
    background-color: #001d4c;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 400;
    color: white;
}
.footer-t{
    max-width: 1140px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 35px 0;
    border-bottom: 1px solid rgba(255,255,255,.1);
    font-weight: 400;
}
.footer-logo>img{
    width: 50%;
    height: 100%;

}
.footer-email{
    display: flex;
    margin-left: 400px;
}
.footer-email>img{
    width: 20%;
    height: 100%;
    margin: 0rem 1rem;
}
.footer-email>div>h4 ,.footer-email>div>h3{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7333333333;
    cursor: pointer;
}
.footer-b{
    max-width: 1140px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding: 60px 0;
    font-weight: 400;
    letter-spacing: .3px;
    display: flex;
}
.footer-bBox{
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: 400;
    letter-spacing: .3px;
    flex: 0 0 25%;
    max-width: 25%;
}
.footer-bBox>h1{
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
}
.footer-bBox>h3{
    font-weight: 400;
    font-size: 1.0666666667rem;
    display: block;
    color: #fff;
}
.footer-bBox>h5{
    color: #4ac1c3;
    font-size: .8666666667rem;
    margin-bottom: 25px;
    font-weight: 400;
    letter-spacing: .3px;
    display: flex;
}
.footer-bBox>ul{
    list-style-type: none;
}
.footer-bBox>ul>li{
    margin-bottom: 22px;
    margin-left: 20px;
}

.footer-bBox>input ,.footer-bBox>textarea{
    border: 1px solid rgba(255,255,255,.08);
    border-radius: 3px;
    background-color: rgba(0,0,0,.23);
    height: 49px;
    margin-bottom: 20px;
    padding-left: 20px;
    color: #b2b2b2;
    font-size: 14px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    font-size: 1rem;
}
.footer-bBox>textarea{
    height: 85px;
    resize: none;
}
.footer-btn{
    border: 1px solid #0c5adb;
    border-radius: 3px;
    height: 49px;
    background-color: transparent;
    color: #fff;
    padding: 0 28px;
    cursor: pointer;
    max-width: 150px;
    padding: 0 28px;
    font-size: 1rem;
}
.footer-d{
    padding: 85px 0 25px;
    background-color: #00173e;
    width: 100%;
}
.footer-main{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
}
.footer-d-l{
    margin-bottom: 25px;
    flex: 0 0 50%;
    max-width: 50%;
}
.footer-d-l>h3{
    color: #fff;
    letter-spacing: .3px;
    font-size: .9333333333rem;
}
.footer-d-r{
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: right;
}
.copy-box{
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    margin-right: 5px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
}
.copy-box:nth-child(1){
    background-color: #e8715c;
}
.copy-box:nth-child(2){
    background-color: #3b5998;
}