.pannel-copy{
    width: 100%;
    background-color: white;
    padding: 15px;
    color: #212529;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
}
.pannel-copy>h1{
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.42857143;
}